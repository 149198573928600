
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'PlayerNotification',
  components: { SaIcon },
  props: {
    show: {
      type: Boolean,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      titleWidth: undefined as number | undefined,
    }
  },
  computed: {
    loaded(): boolean {
      return this.titleWidth !== undefined
    },
    buttonClasses(): string {
      let classes = this.loaded ? 'loaded' : 'not-loaded'
      if (this.show) {
        classes += ' show'
      }
      return classes
    },
  },
  watch: {
    show() {
      this.updateWidth()
    },
  },
  mounted() {
    this.updateWidth()
  },
  methods: {
    updateWidth() {
      const title = this.$refs.title as HTMLElement
      this.titleWidth = undefined
      this.$nextTick(() => {
        this.titleWidth = title.clientWidth
      })
    },
  },
})
