// Enum for Schema.org Types
import {
  Article,
  AudioObject,
  CreativeWorkSeries,
  Graph,
  Organization,
  Person,
  PostalAddress,
  Thing,
  VideoObject,
} from 'schema-dts'
import { Sermon } from '~/models/sermon'
import {
  embedSermonUrl,
  GetArticleUrl,
  siteBroadcasterUrl,
  siteSeriesUrl,
  siteSermonUrl,
  siteShareUrl,
  siteSpeakerUrl,
} from '~/assets/ts/utils/urls'
import { ArticleDisplay, PlayerMediaType } from '~/assets/ts/enums'
import { Broadcaster } from '~/models/broadcaster'
import { Speaker } from '~/models/speaker'
import { Series } from '~/models/series'
import { ArticleApi } from '~/apiclient/apiarticles'
import { stripMarkdown } from '~/assets/ts/markdown/renderer'
import { countWords } from '~/assets/ts/utils/strings'
import { apiDateToJsDate, apiTimestampToJsDate } from '~/assets/ts/utils/date'

function secondsToISO8601(seconds: number): string {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  let isoDuration = 'PT' // Start with the 'T' indicating time portion

  if (hours > 0) isoDuration += `${hours}H`
  if (minutes > 0) isoDuration += `${minutes}M`
  if (remainingSeconds > 0) isoDuration += `${remainingSeconds}S`

  return isoDuration
}

export type StructuredData = Graph

function graph(graphs: Thing[]): StructuredData {
  return {
    '@context': 'https://schema.org',
    '@graph': graphs,
  }
}

function speakerUrl(speaker: Speaker | number) {
  return siteShareUrl(siteSpeakerUrl(speaker))
}

function createSpeaker(speaker: Speaker): Person {
  const url = speakerUrl(speaker)
  return {
    '@type': 'Person',
    '@id': url,
    name: speaker.displayName,
    image: speaker.albumArt(512),
    description: speaker.bio,
    url,
  }
}

function broadcasterUrl(broadcaster: Broadcaster | string) {
  return siteShareUrl(siteBroadcasterUrl(broadcaster))
}

// https://developers.google.com/search/docs/appearance/structured-data/organization
function createBroadcasterOrg(broadcaster: Broadcaster): Organization {
  const { school, seminary, radioBroadcast } = broadcaster.categories
  const url = broadcasterUrl(broadcaster)
  let address = undefined as PostalAddress | undefined
  if (broadcaster.address) {
    address = {
      '@type': 'PostalAddress',
      streetAddress: broadcaster.address,
    }
  }
  return {
    '@type': radioBroadcast
      ? 'RadioStation'
      : school
        ? 'School'
        : seminary
          ? 'CollegeOrUniversity'
          : 'Organization',
    '@id': url,
    name: broadcaster.displayName,
    image: broadcaster.imageURL,
    address,
    description: broadcaster.aboutUs || undefined,
    telephone: broadcaster.phone || undefined,
    mainEntityOfPage: url,
  }
}

function sermonUrl(sermon: Sermon) {
  return siteShareUrl(siteSermonUrl(sermon))
}

function id(url: string) {
  return {
    '@id': url,
  }
}

// https://developers.google.com/search/docs/appearance/structured-data/video#json-ld
function createSermonMedia(
  sermon: Sermon,
  type: PlayerMediaType
): AudioObject | VideoObject {
  const video = type === PlayerMediaType.Video
  const url = sermonUrl(sermon)
  const thumbnailUrl = video ? sermon.thumbnailURL(1920) : undefined
  const seconds = video
    ? sermon.videoDurationSeconds
    : sermon.audioDurationSeconds
  const contentUrl = video
    ? sermon.videoPreviewURL
    : sermon.media.highestAudio?.streamURL
  return {
    '@type': video ? 'VideoObject' : 'AudioObject',
    '@id': url,
    url,
    name: sermon.fullTitle,
    description: sermon.moreInfoText ?? sermon.metadata,
    uploadDate: sermon.preachDate.toISOString(),
    keywords: sermon.hashtags.map((h) => h.hashtag).join(','),
    mainEntityOfPage: url,
    thumbnailUrl,
    duration: seconds ? secondsToISO8601(seconds) : undefined,
    embedUrl: contentUrl
      ? siteShareUrl(embedSermonUrl(sermon.id, type))
      : undefined,
    contentUrl,
    author: id(speakerUrl(sermon.speaker)),
    isPartOf: sermon.series ? id(seriesUrl(sermon.series)) : undefined,
  }
}

function seriesUrl(series: Series) {
  return siteShareUrl(siteSeriesUrl(series))
}

function createSeries(series: Series): CreativeWorkSeries {
  const url = seriesUrl(series)
  return {
    '@type': series.isPodcast ? 'PodcastSeries' : 'CreativeWorkSeries',
    '@id': url,
    name: series.title,
    description: series.description,
    startDate: series.earliest?.toISOString(),
    endDate: series.latest?.toISOString(),
    image: series.albumArt(512),
    mainEntityOfPage: seriesUrl(series),
    url,
  }
}

function articleUrl(article: ArticleApi, display: ArticleDisplay) {
  return siteShareUrl(GetArticleUrl(display, article))
}

function createArticle(
  article: ArticleApi,
  display: ArticleDisplay,
  broadcaster: Broadcaster,
  speaker: Speaker | undefined
): Article {
  const url = articleUrl(article, display)
  const news = [ArticleDisplay.News, ArticleDisplay.ReleaseNotes].includes(
    display
  )
  const articleBody = stripMarkdown(article.content)
  return {
    '@type': news ? 'NewsArticle' : 'Article',
    '@id': url,
    name: article.title,
    headline: article.title,
    articleBody,
    articleSection: article.categoryDescription || undefined,
    wordCount: countWords(articleBody),
    author: speaker ? id(speakerUrl(speaker)) : undefined,
    datePublished: (apiDateToJsDate(article.articleDate) as Date).toISOString(),
    dateModified: apiTimestampToJsDate(article.updateTimestamp).toISOString(),
    mainEntityOfPage: url,
    thumbnailUrl: article.bannerURL || undefined,
    image: article.bannerURL || undefined,
    sourceOrganization: id(broadcasterUrl(broadcaster)),
    url,
  }
}

export function StructuredDataSermon(sermon: Sermon): StructuredData {
  const graphs = [
    createSpeaker(sermon.speaker),
    createBroadcasterOrg(sermon.broadcaster),
  ] as Thing[]
  if (sermon.series) {
    graphs.unshift(createSeries(sermon.series))
  }
  if (sermon.hasVideo) {
    graphs.unshift(createSermonMedia(sermon, PlayerMediaType.Video))
  } else {
    graphs.unshift(createSermonMedia(sermon, PlayerMediaType.Audio))
  }
  return graph(graphs)
}

export function StructuredDataBroadcaster(
  broadcaster: Broadcaster
): StructuredData {
  return graph([createBroadcasterOrg(broadcaster)])
}

export function StructuredDataSpeaker(speaker: Speaker): StructuredData {
  return graph([createSpeaker(speaker)])
}

export function StructuredDataSeries(series: Series): StructuredData {
  return graph([createSeries(series), createBroadcasterOrg(series.broadcaster)])
}

export function StructuredDataArticle(
  article: ArticleApi,
  display: ArticleDisplay
): StructuredData {
  const broadcaster = new Broadcaster(article.broadcaster)
  const speaker = article.speaker ? new Speaker(article.speaker) : undefined
  const graphs = [
    createArticle(article, display, broadcaster, speaker),
    createBroadcasterOrg(broadcaster),
  ] as Thing[]
  if (speaker) {
    graphs.push(createSpeaker(speaker))
  }
  return graph(graphs)
}
