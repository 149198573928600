
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import Collapsible from '~/components/_general/Collapsible.vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'AccordionElement',
  components: { SaIcon, Collapsible },
  model: {
    prop: 'open',
    event: 'change',
  },
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    icon: {
      type: String as PropType<SaIconsType>,
      default: '',
    },
    titleClasses: {
      type: String,
      default: 'font-semibold',
    },
    buttonClasses: {
      type: String,
      default: '',
    },
    textClasses: {
      type: String,
      default: '',
    },
    iconClasses: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
    },
    prerender: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    transitions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      collapsed: !this.open,
    }
  },
  watch: {
    open() {
      this.collapsed = !this.open
    },
    collapsed() {
      this.$emit('change', !this.collapsed)
    },
  },
  methods: {
    clickEvent(click: () => void) {
      if (this.disabled) return
      click()
    },
  },
})
