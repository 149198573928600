
import { TranslateResult } from 'vue-i18n'
import Vue, { PropType } from 'vue'
import PhoneNumber from '~/components/_general/PhoneNumber.vue'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'
import DateDisplay from '~/components/_general/DateDisplay.vue'

export interface TableData {
  label: TranslateResult
  value: number | TranslateResult | undefined | null
  phone?: boolean
  href?: string
  to?: string
  date?: Date
}
export default Vue.extend({
  name: 'SiteDataTable',
  components: { DateDisplay, SaLinkOrHref, PhoneNumber },
  props: {
    data: {
      type: Array as PropType<TableData[]>,
      default: () => [],
    },
    small: {
      type: Boolean,
    },
  },
  computed: {
    validData(): TableData[] {
      return this.data?.filter((datum) => !!datum.value)
    },
  },
})
