
import Vue from 'vue'
import { Srt } from '~/assets/ts/utils/srt'
import { secondsToHhMmSs } from '~/assets/ts/utils/date'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import { convertLineBreaks } from '~/assets/ts/utils/strings'
import KeydownEvent from '~/models/generic/KeydownEvent'
import { clamp } from '~/assets/ts/utils/math'
import SiteLoadingIndicator from '~/components/site/LoadingIndicator.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import IconCircleButton from '~/components/_general/IconCircleButton.vue'
import SiteButton from '~/components/site/Button.vue'

export default Vue.extend({
  name: 'PlayerTranscriptElement',
  components: {
    SiteButton,
    IconCircleButton,
    InlineIcon,
    KeydownObserver,
    SaIcon,
    SiteLoadingIndicator,
  },
  props: {
    sermonId: {
      type: String,
      required: true,
    },
    languageCode3: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
    },
    playerTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      parser: undefined as any | undefined,
      currentSrt: 0,
      search: true,
      searchText: '',
      searchIndex: 0,
      searchSrtIndexes: [] as number[],
      autoScroll: true,
      scrollTimeout: 0,
    }
  },
  computed: {
    jumpToCurrentBuffer(): number {
      return 25
    },
    /** Value shown to the user as the selected search item */
    displaySearchIndex(): number {
      return this.searchIndex + 1
    },
    /** Srt index of the current search item */
    searchSrtIndex(): number {
      return this.searchSrtIndexes[this.searchIndex]
    },
    srtArray(): Srt[] {
      return (
        this.$store.getters['sermons/srt'](this.sermonId, this.languageCode3) ??
        []
      )
    },
    searchMatches(): number {
      return this.searchSrtIndexes.length
    },
    searchInput(): HTMLInputElement | undefined {
      return this.$refs.search as HTMLInputElement
    },
    scrollBody(): HTMLElement | undefined {
      return this.$refs.body as HTMLElement
    },
    text(): string {
      return this.srtArray
        ?.map((s) => s.text)
        .join(' ')
        .replaceAll('\n', ' ')
    },
  },
  watch: {
    searchText() {
      this.getSearchMatches()
    },
    playerTime() {
      if (!this.srtArray) return
      const t = this.playerTime
      const i = this.srtArray.findIndex((srt) => {
        return srt.startTime <= t && srt.endTime >= t
      })
      if (i !== -1) {
        this.currentSrt = i
      }
    },
    currentSrt() {
      this.scrollToCurrent()
      this.scrolled()
    },
    show() {
      if (!this.show) return
      this.startSearch()
    },
  },
  async mounted() {
    this.addScrollListener()
    if (this.srtArray.length) {
      await waitOneFrame()
      this.scrollToCurrent()
    }
  },
  destroyed() {
    this.removeScrollListener()
  },
  methods: {
    clearSearch() {
      this.searchText = ''
    },
    keydown(key: KeydownEvent) {
      if (key.Escape) {
        this.clearSearch()
      } else if (key.Enter) {
        this.searchNext()
      } else if (key.ShiftEnter) {
        this.searchPrev()
      }
      // else if (key.Slash) {
      //   key.event.preventDefault()
      //   this.startSearch()
      // }
    },
    getSrtElement(index: number): HTMLButtonElement | undefined {
      if (!this.srtArray.length) return undefined
      const array = this.$refs[`srt-${index}`] as HTMLButtonElement[]
      return array.length ? array[0] : undefined
    },
    addScrollListener() {
      this.scrollBody?.addEventListener('scroll', this.scrolled)
    },
    removeScrollListener() {
      this.scrollBody?.removeEventListener('scroll', this.scrolled)
    },
    scrolled() {
      this.autoScroll = this.currentIsInView()
    },
    enableAutoScroll() {
      this.autoScroll = true
      this.scrollToCurrent()
    },
    getIndexScrollPosition(index: number): number | undefined {
      if (!this.scrollBody) return undefined
      const el = this.getSrtElement(index)
      if (!el) return undefined
      const height = this.scrollBody.clientHeight
      const offset = height / 1.75
      const max = this.scrollBody.scrollHeight - height
      return clamp(el.offsetTop - offset, 0, max)
    },
    currentIsInView(): boolean {
      const scrollPos = this.getIndexScrollPosition(this.currentSrt)
      if (!this.scrollBody || scrollPos === undefined) return true
      const scrollTop = this.scrollBody.scrollTop
      const buffer = this.jumpToCurrentBuffer
      return scrollPos >= scrollTop - buffer && scrollPos <= scrollTop + buffer
    },
    scrollToIndex(index: number) {
      if (!this.scrollBody) return
      const scrollPos = this.getIndexScrollPosition(index)
      if (scrollPos === undefined) return
      this.scrollBody.scrollTop = scrollPos
    },
    scrollToCurrent() {
      if (!this.autoScroll) return
      this.scrollToIndex(this.currentSrt)
    },
    scrollToSearch() {
      this.scrollToIndex(this.searchSrtIndex)
    },
    async startSearch() {
      await waitOneFrame()
      this.searchInput?.focus()
    },
    getSearchMatches() {
      this.searchSrtIndexes = []
      if (!this.searchText) return
      const searchText = this.searchText.toLowerCase()
      this.srtArray.forEach((_srt, index) => {
        if (this.srtMatchesSearch(searchText, index)) {
          this.searchSrtIndexes.push(index)
        }
      })
      if (this.searchMatches < 1) return
      if (this.searchSrtIndexes.includes(this.searchIndex)) return
      this.searchIndex = 0
      this.scrollToSearch()
    },
    isMatchedSearch(index: number) {
      return this.searchSrtIndexes.includes(index)
    },
    // feels like this could be a lot more efficient...
    srtMatchesSearch(searchText: string, index: number): boolean {
      const current = convertLineBreaks(
        this.srtArray[index].text.toLowerCase(),
        ' '
      )
      if (current.includes(searchText)) return true

      const next = convertLineBreaks(
        (this.srtArray[index + 1]?.text ?? ' ').toLowerCase(),
        ''
      )
      if (next.includes(searchText)) return false
      return (
        `${current} ${next}`.includes(searchText) ||
        `${current}${next}`.includes(searchText)
      )
    },
    time(seconds: number): string {
      return secondsToHhMmSs(Math.floor(seconds), true)
    },
    searchPrev() {
      if (this.searchIndex === 0) {
        this.searchIndex = this.searchMatches - 1
      } else {
        this.searchIndex -= 1
      }
      this.scrollToSearch()
    },
    searchNext() {
      if (this.searchIndex + 1 === this.searchMatches) {
        this.searchIndex = 0
      } else {
        this.searchIndex += 1
      }
      this.scrollToSearch()
    },
    toggle() {
      const show = !this.show
      this.$emit('show', show)
    },
    goTo(srt: Srt) {
      this.$emit('setTime', srt.startTime)
      this.enableAutoScroll()
    },
  },
})
