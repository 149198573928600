
import Vue, { PropType } from 'vue'
import {
  jsDateToApiDateStr,
  jsDateToApiTimestamp,
} from '~/assets/ts/utils/date'

export default Vue.extend({
  name: 'DateDisplay',
  props: {
    date: {
      type: Date as PropType<Date>,
      required: true,
    },
    time: {
      type: Boolean,
    },
  },
  data() {
    return {}
  },
  computed: {
    datetime(): string {
      if (this.time) {
        return jsDateToApiTimestamp(this.date).toString()
      }
      return jsDateToApiDateStr(this.date)
    },
  },
  methods: {},
})
